import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import api from './../../../../../../Apis'
import CircularProgress from '@mui/material/CircularProgress';
import Pusher from "pusher-js";
import FormMessage from './Components/FormMessage';
import ManageAttachment from './Components/ManageAttachment';
import Swal from 'sweetalert2'
import Tag from "./Components/Tag";
import Tooltip from '@mui/material/Tooltip';
import { convertNumber } from './../../../../../../Helpers/function.js';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import ReadMoreMess from "./Components/ReadMoreMess";
import {find_phone_number, replaceArrayItemInString} from './../../../../../../Helpers/function';
import parse from 'html-react-parser';
import { Alert, Avatar } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';
import Employee from "./Components/Employee";
import Attachment from './Components/Attachment';
import ActionComment from "./Components/ActionComment";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { getPhoneFromMessage } from './../../../../../../Store/Actions/GetPhoneFromMessage';
import { useTranslation } from "react-i18next";

function Index(props) {
    const { t, i18n } = useTranslation();
    const {register, handleSubmit} = useForm();
    const [state, setState] = useState({
        messages: [],
        conversation_tag: [],
        tag_active: '',
        conversation_id: '',
        message: '',
        picture_fanpage: '',
        name_sender: '',
        phone: '',
        participant_id: null,
        status_send: false,
        message_send: [],
        tags: [],
        mess_sample: '',
        loads: {
            read_more_mess: false,
            init_mess: true
        },
        status_copy: false,
        text_copy: '',
        is_done: 0,
        type: {
            'name': '',
            'link': ''
        },
        employees: [],
        saler: {id: '', name: ''},
        content_post: null,
        post_id: null,
        status_scroll: true,
    });
    const data_redux = useSelector(state => state.message);
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    useEffect(() => {
        if (data_redux.conversation_id == props.state.conversation_id && data_redux.message != '') {
            setState((oldState) => {
                const message_send = [...oldState.message_send];
                if (oldState.type.name == 'comment') {
                    message_send.splice(0, message_send.length);
                } else {
                    message_send.shift();
                }
                /**
                 * Nếu tin nhắn đó là xóa thì xóa tin nhắn đó đi
                 * Ngược lại thì nối tin nhắn đó vào đuôi
                 */
                if (data_redux.is_deleted) {
                    const message_copy = [...oldState.messages];
                    for (var i = 0; i < message_copy.length; i++) {
                        if (message_copy[i].mess_id == data_redux.mess_id) {
                            message_copy.splice(i, 1);
                        }
                    }
                    return ({...oldState, message_send: message_send, messages: message_copy})
                } else {
                    const message_copy = [...oldState.messages, data_redux.message];
                    const messages = [...new Map(message_copy.map(item =>
                    [item['mess_id'], item])).values()];

                    return ({...oldState, message_send: message_send, messages: messages})
                }
            });
            
            // const message_copy = [...state.messages, data_redux.message];
            // const messages = [...new Map(message_copy.map(item =>
            //     [item['mess_id'], item])).values()];
            // setState({
            //     ...state,
            //     messages: messages,
            //     message_send: []
            // })
            setTimeout(() => {
                elementRef.current.scrollIntoView();
            }, 150);
        }
    }, [data_redux.message])

    useEffect(() => {
        if (props.state.participant_id != null) {
            setState({
                ...state,
                loads: {...state.loads, init_mess: true}
            });

            /**
             * Sử dụng pusher để nhận tin nhắn realtime
             * Comment đi vì đã dùng redux ở trên
             */
            // Pusher.logToConsole = false;
            // const pusher = new Pusher('599c142c597b726c859e', {
            //     cluster: 'ap1'
            // });
            // const channel = pusher.subscribe('channel-' + props.state.conversation_id);
            // channel.bind('event-' + props.state.conversation_id, function (data) {
            //     if (data.channel == props.state.conversation_id) {  
            //         setState((oldState) => {
            //             return ({...oldState, message_send: [], messages: [...oldState.messages, data.message]})
            //         });
            //     }
            // });
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookies.is_login
                },
                body: JSON.stringify({
                    participant_id: props.state.participant_id,
                    conversation_id: props.state.conversation_id,
                    fanpage_id: props.fanpage_id,
                    status_read: props.state.status_read,
                    lang: i18n.language
                })
            };
            fetch(api.get_message, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        const txt = result.message_all;
                        const phone = txt.match(/[\+]?\d{10}|\(\d{3}\)\s?-\d{6}/gm);
                        setState({...state,
                            'messages': result.datas,
                            'conversation_tag': result.conversation_tag,
                            'picture_fanpage': result.picture_fanpage,
                            'loads': {...state.loads, init_mess: false},
                            'conversation_id': props.state.conversation_id,
                            'name_sender': props.state.name_sender,
                            'tags': result.tags,
                            'is_done': result.is_done,
                            'type': result.type,
                            'employees': result.employees,
                            'saler': result.saler,
                            'content_post': result.content_post,
                            'post_id': result.post_id
                        });
                        if (Array.isArray(phone)) {
                            const action = getPhoneFromMessage(phone[phone.length - 1]);
                            dispatch(action);
                            // props.get_phone(phone[phone.length - 1]);
                        } else {
                            const action = getPhoneFromMessage('');
                            dispatch(action);
                        }
                        setTimeout(() => {
                            elementRef.current.scrollIntoView();
                        }, 50);
                    } else {
                        setState({...state,
                            messages:result.messages,
                            loads: {...state.loads, init_mess: false}
                        });
                    }
                }
            )
        }
    }, [props.state.conversation_id]);

    const onSendAttchment = (data) => {
        const new_obj = {
            message: '',
            sending: false,
            attachments: [{
                'mime_type': state.type.name == 'messenger' ? "image/jpeg" : null,
                'file_url': null,
                'video_data': null,
                'image_data': data
            }],
            created_at: '',
            time: '',
            is_hidden: false,
            sender_id: props.fanpage_id,
            sender_name: props.state.fanpage_name,
        }
        const api_link = state.type.name == 'messenger' ? api.send_attachment : api.send_attachment_comment;
        const message_send = [...state.message_send, new_obj];
        setState({...state, message_send: message_send});
        setTimeout(() => {
            elementRef.current.scrollIntoView();
        }, 150);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookies.is_login
            },
            body: JSON.stringify({
                data: data,
                fanpage_id: props.fanpage_id,
                receive_id: props.state.participant_id,
                sender_name: props.state.fanpage_name,
                attachments: [{
                    mime_type: state.type.name == 'messenger' ? "image/jpeg" : null,
                    file_url: null,
                    video_data: null,
                    image_data: data[0]
                }],
                conversation_id: props.state.conversation_id,
                lang: i18n.language
            })
        };
        fetch(api_link, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        timer: 1500
                    })
                    setState({...state, message_send: []});
                } else {
                    message_send[message_send.length - 1]['sending'] = true;
                    setState({
                        ...state,
                        message_send: message_send
                    })
                }
            }
        )
    }

    const getMessSample = (data) => {
        setState({...state, mess_sample: data});
    }

    // const sendCouponMessage = (data) => {
    //     var content1 = data.content.replace('{page_name}', props.state.fanpage_name);
    //     var content = content1.replace('{name}', props.state.name_sender);
    //     var message = {
    //         from: {id: props.fanpage_id},
    //         attachments: [],
    //         message: content,
    //         sending: false
    //     }
    //     const message_send = [...state.message_send, message];
    //     setState({...state, message_send: message_send});
    //     setTimeout(() => {
    //         elementRef.current.scrollIntoView();
    //     }, 150);
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + props.cookies.is_login
    //         },
    //         body: JSON.stringify({
    //             message: message,
    //             receive_id: props.state.participant_id,
    //             conversation_id: props.state.conversation_id,
    //             sender_name: props.state.fanpage_name,
    //             fanpage_id: props.fanpage_id
    //         })
    //     };
    //     fetch(api.send_coupon_message, requestOptions)
    //     .then(res => res.json())
    //     .then(
    //         (result) => {
    //             if (!result.success) {
    //                 Swal.fire({
    //                     title: result.message,
    //                     icon: 'error',
    //                     confirmButtonText: 'OK',
    //                     timer: 1500
    //                 })
    //                 setState({...state, message_send: []});
    //             } else {
    //                 message_send[message_send.length - 1]['sending'] = true;
    //                 setState({
    //                     ...state,
    //                     message_send: message_send
    //                 })
    //             }
    //         }
    //     )
    // }

    const onSendMessage = (message, attachments) => {
        const message_send = [...state.message_send];
        if (message != '') {
            message_send.push({
                from: {id: props.fanpage_id},
                attachments: [],
                message: message,
                sending: false
            });
        }
        if (attachments.length > 0) {
            for (var i = 0; i < attachments.length; i++) {
                message_send.push({
                    from: {id: props.fanpage_id},
                    attachments: [attachments[i]],
                    message: '',
                    sending: false
                })
            }
        }
        if (message == '' && attachments.length == 0) {
            Swal.fire({
                title: 'Yêu cầu nhập tin nhắn hoặc chọn ảnh',
                icon: 'warning'
            })
        } else if (state.type.name == 'comment' && attachments.length > 1) {
            Swal.fire({
                title: 'Bình luận chỉ được phép gửi 1 ảnh',
                icon: 'warning',
                timer: 1500
            })
        } else {
            setState((oldState) => {
                return ({...oldState, message_send: message_send})
            });
            setTimeout(() => {
                elementRef.current.scrollIntoView();
            }, 150);
            const api_link = state.type.name == 'messenger' ? api.send_message : api.send_comment;
            if (state.type.name != 'messenger' && attachments.length > 2) {
                Swal.fire({
                    title: t("liveChat.commentImageRestriction"),
                    icon: 'warning'
                })
            } else {
                 const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookies.is_login
                    },
                    body: JSON.stringify({
                        message: message,
                        fanpage_id: props.fanpage_id,
                        receive_id: props.state.participant_id,
                        sender_name: props.state.fanpage_name,
                        conversation_id: props.state.conversation_id,
                        attachments: attachments,
                        lang: i18n.language
                    })
                };
                fetch(api_link, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                confirmButtonText: 'OK',
                            })
                            setState((oldState) => {
                                return ({...oldState, message_send: []})
                            });
                        }
                    }
                )
            }
        }
    }
    const handleUserKeyPress = e => {
        if (e.key === "Enter" && !e.shiftKey) {
            handleSubmit(onSendMessage)();
        }
    };
    const updateReadConversation = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookies.is_login
            },
            body: JSON.stringify({
                conversation_id: props.state.conversation_id,
                fanpage_id: props.fanpage_id,
                lang: i18n.language
            })
        };
        fetch(api.update_read_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.update_status_read();
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'Thoát'
                    })
                }
            }
        )
    }

    const finishConversation = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookies.is_login
            },
            body: JSON.stringify({
                conversation_id: props.state.conversation_id,
                fanpage_id: props.fanpage_id,
                lang: i18n.language
            })
        };
        fetch(api.finish_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    props.finishConversation();
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'Thoát',
                        timer: 1000
                    })
                }
            }
        )
    }

    const readMoreMess = () => {

    }

    const updateTag = (data) => {
        props.update_tag(data);
    }

    const responsePropsMessage = (response) => {
        const old_mess = [...state.messages];
        const mess_merge = response.concat(old_mess);
        const new_messages = [...new Map(mess_merge.map(item =>
            [item['mess_id'], item])).values()];
        setState({...state, messages: new_messages});
    }
    // const AlwaysScrollToBottom = () => {
    //     const elementRef = useRef(null);
    //     useEffect(() => elementRef.current.scrollIntoView());

    //     return <div ref={elementRef} />;
    // };
    
    // useEffect(() => {
    //     if (elementRef.current) {
    //         elementRef.current.scrollIntoView();
    //     }
    // }, [state.messages])

    const deleteComment = (data, index) => {
        const comment_delete = state.messages[index];
        const copy_message = [...state.messages];
        copy_message.splice(index, 1);
        setState({
            ...state,
            messages: copy_message
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookies.is_login
            },
            body: JSON.stringify({
                mess_id: data.mess_id,
                fanpage_id: props.fanpage_id,
                lang: i18n.language
            })
        };
        fetch(api.delete_comment, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    if (result.number_comment_delete == 'all') {
                        setState({
                            ...state,
                            messages: []
                        })
                    }
                    // const copy_message = [...state.messages];
                    // copy_message.splice(index, 1);
                    // setState({
                    //     ...state,
                    //     messages: copy_message
                    // })   
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'Thoát',
                    })
                    setState({
                        ...state,
                        messages: [...state.messages, comment_delete]
                    })
                }
            }
        )
    }

    const hiddenComment = (data, index) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookies.is_login
            },
            body: JSON.stringify({
                mess_id: data.mess_id,
                fanpage_id: props.fanpage_id,
                lang: i18n.language
            })
        };
        fetch(api.hidden_comment, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                  
                        const copy_message = [...state.messages];
                        copy_message[index].is_hidden = !data.is_hidden;
                        setState({
                            ...state,
                            messages: copy_message
                        })
                    
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'Thoát',
                    })
                }
            }
        )
    }

    const checkPhoneNumber = (mess, data, index) => {
        const split = mess.split('</span>');
        const filter = /<span>.*<\/span>/;
        return (
            <div className='mess-text'>
                {
                    split.map(t => {
                        const [text, bold] = t.split('<span>')
                        return <span>
                                    {text}
                                    {
                                        bold !== undefined &&
                                        <b className="cursor-copy highlight-phone-number" onClick={() => copyPhoneNumber(bold)}>{bold === undefined ? '' : bold}</b>
                                    }
                                    
                                </span>
                    })
                }
                {
                    state.type.name == 'comment' &&
                    <ActionComment
                        deleteComment={deleteComment}
                        fanpage_id={props.fanpage_id}
                        conversation_id={props.state.conversation_id}
                        name_sender={state.name_sender}
                        hiddenComment={hiddenComment}
                        data={data}
                        index={index}
                        cookie={props.cookies.is_login}
                    />
                }

            </div>
        )
    }

    const copyPhoneNumber = (phone) => {
        navigator.clipboard.writeText(phone);
        setState({
            ...state,
            status_copy: true,
            text_copy: 'Copied ' + phone
        });
        setTimeout(() => {
            setState({
                ...state,
                status_copy: false,
                text_copy: '',
            });
        }, 2000);
    }

    const update_saler = (name, id) => {
        setState({
            ...state,
            saler: {
                name: name,
                id: id
            }
        })
    }

    const renderAttachmentReply = (data) => {
        if (data.length > 0) {
            if (data[0].mime_type == "image/jpeg") {
                return (
                    <img style={{width: "100%"}} src={ data[0].image_data } alt="" />
                )
            }
        }

        return;
    }

    return (
        <div className='chat'>
            {
                state.status_copy &&
                <Alert className="alert-copy" severity="success">
                    {state.text_copy}
                </Alert>
            }
            
            {
                props.state.conversation_id == ''
                ?
                <div className='h-100 chat-empty'>
                   {t("liveChat.conversation.selectConversationFromList")}
                </div>
                :
                <div className="h-100">
                    {
                        props.state.name_sender != '' &&
                        <div className='info-user-chat'>
                            <div className='info'>
                                <div className='avatar'>
                                    <img src={props.state.avatar_user}></img>
                                </div>
                                <div className='full-name'>
                                    {props.state.name_sender}
                                    {
                                        state.saler.name != '' &&
                                        <span style={{fontSize: '12px', marginLeft: '15px'}} className="label label-success">Saler: {state.saler.name}</span>
                                    }
                                </div>
                            </div>
                            <div className='setting'>
                                <Employee fanpage_id={props.fanpage_id} update_saler={update_saler} cookie={props.cookies.is_login} conversation_id={props.state.conversation_id} employees={state.employees}/>
                                <div>
                                    <Tooltip title={t("liveChat.conversation.markAsUnread")} arrow placement="top">
                                        <VisibilityOffOutlinedIcon onClick={updateReadConversation} className="icon"/>
                                    </Tooltip>
                                    {
                                        state.is_done == 0
                                        ?
                                        <Tooltip arrow title={t("liveChat.conversation.moveToFolderDone")} onClick={finishConversation} placement="top">
                                            <CheckRoundedIcon className="icon"/>
                                        </Tooltip>
                                        :
                                        <Tooltip arrow title={t("liveChat.conversation.reopenConversation")} onClick={finishConversation} placement="top">
                                            <ShortcutRoundedIcon className="icon"/>
                                        </Tooltip>
                                    }
                                    
                                    <a target="_blank" href={state.type.link}>
                                        <Tooltip arrow title={t("liveChat.conversation.viewOnFacebook")} placement="top">
                                                <CallMadeOutlinedIcon className="icon"/>
                                        </Tooltip>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='detail-mess'>
                        {
                            <div>
                                {
                                    state.type.name == 'comment' &&
                                    <div style={{padding: '0px 20px'}}>
                                        {
                                            state.post_id != null &&
                                            <p>
                                                PostID: 
                                                    <b onClick={() => copyPhoneNumber(state.post_id)} className="cursor-copy highlight-phone-number">
                                                        {state.post_id}
                                                    </b>
                                            </p> 
                                        }
                                        {
                                            state.content_post != null && parse(state.content_post)

                                        }
                                        <hr></hr>
                                    </div>
                                }
                                {
                                    state.type.name == 'messenger' &&
                                    <ReadMoreMess 
                                        responsePropsMessage={responsePropsMessage}
                                        count_mess={Array.isArray(state.messages) ? state.messages.length : 0}
                                        fanpage_id={props.fanpage_id} 
                                        cookie={props.cookies.is_login} 
                                        conversation_id={state.conversation_id}
                                    />
                                }
                                {
                                    state.loads.init_mess == true
                                    ? 
                                        <center>
                                            <CircularProgress></CircularProgress>
                                        </center>
                                    :
                                    <div>
                                        {
                                            Array.isArray(state.messages)
                                            ?
                                                state.messages.map((value, key) => {
                                                    let position_mess = value.sender_id != props.fanpage_id ? 'mess-left' : 'mess-right';
                                                    if (position_mess == 'mess-left' && find_phone_number(value.message) != null) {
                                                        const phone_number = find_phone_number(value.message);
                                                        var text_mess = replaceArrayItemInString(value.message, phone_number);
                                                    } else {
                                                        var text_mess = value.message;
                                                    }
                                                    return (
                                                        <div className='m-b-20'>
                                                            {
                                                                (value?.reply_to?.text != null || value?.reply_to?.attachment != null) &&
                                                                <div>
                                                                    <div className={position_mess + ' clear'} style={{padding: "0px 20px", color: "#ccc", fontSize: "12px"}}>{ value.sender_name } {t("liveChat.conversation.replyMessage")}</div>
                                                                    <div className={position_mess + ' clear'}>
                                                                        <div className="message-reply">
                                                                            {
                                                                                value?.reply_to?.text !== null
                                                                                ?
                                                                                <div>{ value?.reply_to?.text }</div>
                                                                                :
                                                                                renderAttachmentReply(value?.reply_to?.attachment)
                                                                            }
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                
                                                            }
                                                            {
                                                                value.message != null && value.message != '' ?
                                                                    position_mess == 'mess-left'
                                                                    ?
                                                                    <div className={position_mess + ' clear'}>
                                                                        <div className='avatar'>
                                                                            <Avatar alt={props.state.name_sender} src={props.state.avatar_user}/>
                                                                        </div>
                                                                        <Tooltip arrow title={value.created_at} placement="right">
                                                                            {checkPhoneNumber(text_mess, value, key)}
                                                                        </Tooltip>
                                                                    </div>
                                                                    :
                                                                    <div className={position_mess + ' clear'}>
                                                                        <Tooltip arrow title={value.created_at} placement="left">
                                                                            <div className='mess-text'>
                                                                                {value.message}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                :
                                                                ''
                                                            }
                                                            {
                                                                value.attachment.length > 0 &&
                                                                <Attachment 
                                                                    sending={true}
                                                                    position_mess={position_mess}
                                                                    attachment={true}
                                                                    attachments={value.attachment}
                                                                />
                                                            }
                                                        </div>
                                                    )
                                                })
                                            :
                                            <center>
                                                {
                                                    state.messages
                                                }
                                            </center>
                                        }
                                    </div>
                                }
                                {
                                    state.message_send.length > 0 &&
                                    state.message_send.map((value, key) => {
                                        return (
                                            <div className="m-b-20">
                                                <div className='mess-right clear'>
                                                    {
                                                        value.message != '' &&
                                                        <div className='mess-text'>
                                                            {
                                                                value.message
                                                            }
                                                            {
                                                                !value.sending &&
                                                                <CheckCircleRoundedIcon className="check-send"/>
                                                            }
                                                            
                                                        </div>
                                                    }
                                                    
                                                </div>
                                                {
                                                    value.attachments.length > 0 &&
                                                    <Attachment
                                                        position_mess='mess-right'
                                                        sending={value.sending}
                                                        // attachment={attachment}
                                                        attachments={value.attachments}
                                                    />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        <div ref={elementRef}></div>
                    </div>
                    {
                        props.state.conversation_id != '' &&
                        <div className='write-mess'>
                            <Tag
                                update_tag={updateTag}
                                stt_conversation={props.state.stt_conversation}
                                tags={props.state.tags}
                                conversation_id={props.state.conversation_id}
                                conversation_tag={state.conversation_tag}
                                fanpage_id={props.fanpage_id}
                            />
                            <div className="form-chat">
                                <Avatar alt={props.state.fanpage_name} src={props.state.picture_fanpage}/>
                                {/* <img className="avatar" src={props.state.picture_fanpage}></img> */}
                                <FormMessage
                                    mess_sample={props.state.mess_samples}
                                    name_sender={props.state.name_sender}
                                    fanpage_name={props.state.fanpage_name}
                                    onSendMessage={onSendMessage}
                                />
                            </div>
                            <ManageAttachment
                                getMessSample={getMessSample}
                                name_sender={props.state.name_sender}
                                fanpage_name={props.state.fanpage_name}
                                mess_samples={props.state.mess_samples}
                                cookie={props.cookies.is_login}
                                fanpage_id={props.fanpage_id}
                                onSendAttchment={onSendAttchment}
                                coupon_messages={props.state.data_api?.coupon_messages}
                                // sendCouponMessage={sendCouponMessage}
                                receive_id={props.state.participant_id}
                                onSendMessage={onSendMessage}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Index;
import api from './../../../../../Apis';
import pusherConfig from './../../../../../Config/pusher';
import { useCookies } from "react-cookie";
import { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Pusher from "pusher-js";
import CircularProgress from '@mui/material/CircularProgress';
import Order from './Orders/Index'
import Chat from './Chats/Index'
import Conversation from "./Conversations/Index"
import ring from "./../../../../../ring.mp3";
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ListOrder from './Orders/ListOrder';
import TabContent from './Orders/TabContent';
import { useDispatch, useSelector } from 'react-redux';
import { message } from './../../../../../Store/Actions/MessageAction';
import { useTranslation } from "react-i18next";

function Index() {
    const { i18n } = useTranslation();
    const date = new Date();
    const month = parseInt(date.getMonth() + 1) < 10 ? '0' + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1);
    const today = date.getDate() + '/' + month + '/' + date.getFullYear();
    const { fanpage_id } = useParams();
    const [cookies, setCookie] = useCookies('is_login');
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [state, setState] = useState({
        conversation_num: 50,
        loading: false,
        conversation_id: '',
        picture_fanpage: '',
        avatar_user: '',
        name_sender: '',
        participant_id: null,
        customers: null,
        tags: [],
        mess_samples: [],
        fanpage_name: '',
        stt_conversation: '',
        tag_actions: {},
        link_conversation: '',
        status_read: false,
        phone_number: '',
        provinces: [],
        districts: [],
        wards: [],
        products: [],
        customer: {
            'name': '',
            'phone_number': '',
            'province': {name: '', id: ''},
            'district': {name: '', id: ''},
            'ward': {name: '', id: ''},
            'address': '',
            'note': ''
        },
        fanpage_id: '',
        status_done_conversation: 'init',
        payment_methods: [],
        transports: [],
        data_api: {},
        transport_id: '',
        uid: '',
        user_id: ''
    });
    const runAudio = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const detailMessage = (data, stt_conversation, obj) => {
        // value.sender_id, value.conversation_id, value.sender_name, key, value.link, value.status_read, value.customer, value.districts, value.wards, value.orders
        // phục vụ cho phần cuộc trò chuyện đổi màu
        setState({...state,
            'conversation_id': data.conversation_id,
            'loading': true,
            'name_sender': data.sender_name,
            'participant_id': data.recive_id == undefined ? data.sender_id : data.recive_id,
            'tags': obj.tags,
            'mess_samples': obj.mess_samples,
            'fanpage_name': data.fanpage.name,
            'picture_fanpage': data.fanpage.picture,
            'stt_conversation': stt_conversation,
            'link_conversation': data.link,
            'phone_number': data.customer.phone_number,
            'products': obj.products,
            'customer': data.customer,
            'payment_methods': obj.payment_methods,
            'transports': obj.transports,
            'avatar_user': data.link_avatar,
            'fanpage_id': data.fanpage.id,
            'transport_id': data.transport_id,
            'provinces': data.provinces,
            'districts': data.districts,
            'wards': data.wards
        })
    }

    const finishConversation = () => {
        setState({
            ...state,
            status_done_conversation: state.status_done_conversation == 'init' ? true : !state.status_done_conversation,
            conversation_id: ''
        })
    }

    const update_status_read = () => {
        setState({...state, status_read: !state.status_read});
    }

    const updateTag = (data) => {
        setState({...state, tag_actions: data});
    }

    const get_phone = (phone_number) => {
        //if (state.customer.phone_number != phone_number) {
            setState({...state, customer: {...state.customer, phone_number: phone_number}});
        //}
        // setState({
        //     ...state,
        //     customer: {...state.customer, phone_number: phone_number}
        // })
    }

    const playAudio = () => {
        new Audio(ring).play();
    }

    const updateConversationId = () => {
        setState({
            ...state,
            conversation_id: ''
        });
    }
    // const data_redux = useSelector(state => state.message);
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                conversation_id: searchParams.get('user_id'),
                limit: state.conversation_num,
                conversation_type: 'not_search',
                lang: i18n.language
            })
        };
        fetch(api.get_conversation, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({
                        ...state,
                        data_api: result,
                        uid: result.uid,
                        user_id: result.user_id
                    });
                    // get_list_conversation_paused();
                    fetch(api.update_time_online, requestOptions);
                }
            }
        )
    // }, [state.fanpage_id])
    }, [])
    
    const get_list_conversation_paused = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                lang: i18n.language
            })
        };
        fetch(api.get_list_conversation_paused, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    fetch(api.update_time_online, requestOptions);
                }
            }
        )
    }

    useEffect(() => {
        if (state.uid != '') {
            Pusher.logToConsole = false;
            const pusher = new Pusher(pusherConfig.dev.key, {
                cluster: pusherConfig.dev.cluster
            });
            const channel = pusher.subscribe('channel-' + state.uid);
            channel.bind('event-' + state.uid, function (data) {
                let employee_ids = [];
                if (data.message != undefined && Array.isArray(data.message.employee_ids)) {
                    employee_ids = data.message.employee_ids;
                    
                } else if (data.data != undefined && Array.isArray(data.data.employee_ids)) {
                    employee_ids = data.data.employee_ids;
                }
                if (data && data.channel == state.uid && employee_ids.includes(state.user_id)) {
                    const action = message({
                        message: data.message != undefined ? data.message : '',
                        new_conversation: data.data != undefined ? data.data : '',
                        conversation_id: data.conversation_id != undefined ? data.conversation_id : ''
                    })
                    dispatch(action);
                }
            });
        }
    }, [state.uid]) //[state.uid]

    return (
        <div className='messenger-box'>
            {/* <audio style={{display: "none"}} controls id="audio" autoPlay>
                <source src={ring} type="audio/mpeg" />
            </audio>
            <button style={{display: "none"}} ref={runAudio} onClick={playAudio}>PLAY AUDIO</button> */}
            <Conversation 
                updateConversationId = {updateConversationId}
                tag_actions={state.tag_actions} 
                stt_conversation={state.stt_conversation} 
                conversation_id={state.conversation_id} 
                // key={fanpage_id} 
                detailMessage={detailMessage} 
                fanpage_id={state.fanpage_id} 
                cookie={cookies.is_login}
                status_read={state.status_read}
                status_done_conversation={state.status_done_conversation}
                data_api={state.data_api}
                conversation_num={state.conversation_num}
            />
            <Chat 
                    state = {state} 
                    //tags = {state.tags} 
                    cookies = {cookies} 
                    //picture_fanpage = {state.picture_fanpage} 
                    fanpage_id = {state.fanpage_id} 
                    today = {today}
                    //mess_samples = {state.mess_samples}
                    //name_sender = {state.name_sender}
                    //fanpage_name = {state.fanpage_name}
                    key = {state.conversation_id}
                    //stt_conversation = {state.stt_conversation}
                    update_tag = {updateTag}
                    update_status_read = {update_status_read}
                    get_phone = {get_phone}
                    finishConversation={finishConversation}
            />
            <div className='book'>
                {
                    state.conversation_id &&
                    <TabContent 
                        fanpage_id={state.fanpage_id}
                        state={state}
                        cookie={cookies.is_login}
                    />
                }
            </div>
        </div>
    )
}

export default Index;
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import api from './../../../../../Apis'
import parse from 'html-react-parser';
import { useForm } from "react-hook-form";
import { Autocomplete, Avatar, Backdrop, Button, Chip, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, Pagination, Stack, TextField } from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useNavigate } from "react-router-dom";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LoadingButton from "@mui/lab/LoadingButton";
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import DeletePost from './Components/DeletePost';
import DialogCreateContent from './Components/DialogCreateContent';
import Swal from "sweetalert2";
import FormSearch from "./Components/FormSearch";
import ProcessPublishPost from "./Components/ProcessPublishPost";
import { requestOptions } from "../../../../../Helpers/function";
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation, Trans } from "react-i18next";

const useRemoveComment = (id, token, post_id, i18n) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            comment_id: id,
            post_id: post_id,
            lang: i18n.language
        })
    };
    fetch(api.delete_comment, requestOptions)
    .then(res => res.json())
    .then(
        (result) => {
            if (result.success == true) {
                alert(result.message);
                window.location.reload();
            } else {
                alert(result.message);
            }
        }
    )
}

function listComment(list, token, post_id, t, i18n) {
    if (Array.isArray(list)) {
        return list.map((value, key) => {
            return (
                <ul className="list-comment">
                    <li>
                        {key+1}) {value.message} <button onClick={() => useRemoveComment(value.id, token, post_id, i18n)}>{t("common.delete")}</button>
                    </li>
                </ul>
            )
        })
    } else if (list == null) {
        return (
            <div>Loading...</div>
        )
    } else {
        return (
            <div>{list}</div>
        )
    }
}

function Index() {
    const { t, i18n } = useTranslation();
    const [modal, setModal] = useState(false);
    const [comment, setComment] = useState(false);
    const [list_comment, setListComment] = useState(null);
    const [modal_list_comment, setModalListComment] = useState(false);
    const [postId, setPostId] = useState('');
    const [content, setContent] = useState('');
    const { id } = useParams();
    const [cookies, setCookie] = useCookies('is_login');
    const [access_token, setCookie1] = useCookies('access_token');
    const [state, setState] = useState({
        dialog: false,
        loading_submit: false,
        group_pages: [],
        content: '',
        post_id: '',
        data_api: null,
        loading_page: true,
        loading_post: true,
        last_page: 1,
        page: 1,
        loading_delete: false,
        dialog_create_post: false,
        index_edit: '',
        fanpage_active: id,
        process: []
    })
    const {register, handleSubmit, setValue} = useForm();
    const {register: registerSearch, handleSubmit: handleSubmitSearch} = useForm();

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: id,
                page: state.page,
                lang: i18n.language
            })
        };
        fetch(api.get_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data_api: result.datas,
                        loading_page: false,
                        loading_post: false,
                        last_page: result.last_page,
                        group_pages: result.group_pages
                    })
                } else {
                    setState({
                        ...state,
                        loading_page: false,
                        loading_post: false
                    })
                }
            }
        )
    }, []);

    const rePublish = (data) => {
        Swal.fire({
            title: t("post.confirmRepost"),
            showDenyButton: true,
            confirmButtonText: t("common.alert.confirm"),
            denyButtonText: t("common.alert.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(api.publish_post_special_to_facebook, requestOptions('POST', cookies.is_login, {id: data.id, fanpage_id: data.fanpage_id, lang: i18n.language}))
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: '1500'
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                            })
                        }
                    }
                )
            }
        })
        
    }

    const showModal = (modal, content, post_id, index) => {
        setState({
            ...state,
            dialog: true,
            post_id: post_id,
            index_edit: index
        })
        setValue("content", content.replaceAll("<br></br>", "\n"))
    }
    const showModalComment = (modal_comment, post_id) => {
        setComment(!modal_comment);
        setPostId(post_id);
    }
    const showModalListComment = (modal_list_comment, post_id) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                post_id: post_id,
                lang: i18n.language
            })
        };
        fetch(api.list_comment, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setModalListComment(!modal_list_comment);
                    setPostId(post_id);
                    setListComment(result.datas);
                } else {
                    setModalListComment(!modal_list_comment);
                    setPostId(post_id);
                    setListComment(result.message);
                }
            }
        )
    }
    const closeModal = (modal) => {
        setModal(!modal);
        setContent('');
        setPostId('');
    }
    const closeModalListComment = (modal) => {
        setModalListComment(!modal);
        setPostId('');
    }
    const closeModalComment = (comment) => {
        setComment(!comment);
        setPostId('');
    }
    const onCommentSubmit = (data) => {
        if (data.message == '') {
            alert(t("chatbot.buildScript.needToEnterContent"));
        } else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.is_login
                },
                body: JSON.stringify({
                    message: data.message,
                    post_id: data.post_id,
                    lang: i18n.language
                })
            };
            fetch(api.comment, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        alert(result.message);
                        window.location.reload();
                    } else {
                        alert(result.message);
                    }
                }
            )
        }
    }
    const onEditPostSubmit = (data) => {
        setState({
            ...state,
            loading_submit: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                content: data.content,
                post_id: state.post_id,
                page: state.page,
                lang: i18n.language
            })
        };
        fetch(api.edit_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const post_copies = [...state.data_api.posts];
                    post_copies[state.index_edit].content = result.new_content;
                    setState({
                        ...state,
                        loading_submit: false,
                        data_api: {
                            ...state.data_api,
                            posts: post_copies
                        },
                        dialog: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success'
                    })
                } else {
                    setState({...state, loading_submit: false})
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                }
            }
        )
    }
    let navigate = useNavigate();

    const search = (fanpage_id) => {
        setState({
            ...state,
            loading_post: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: fanpage_id,
                page: 1,
                lang: i18n.language
            })
        };
        fetch(api.get_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data_api: {
                            ...state.data_api,
                            posts: result.datas.posts,
                            fanpage: result.datas.fanpage
                        },
                        loading_post: false,
                        fanpage_active: fanpage_id,
                        last_page: result.last_page
                    })
                }
            }
        )
    }

    const deletePost = (index, message) => {
        const posts = [...state.data_api.posts];
        posts.splice(index, 1);
        Swal.fire({
            title: message,
            icon: 'success',
            timer: 1500
        })
        setState({
            ...state,
            data_api: {
                ...state.data_api,
                posts: posts
            }
        })
        
    }

    const handleChangePage = (e, page_num) => {
        setState({
            ...state,
            loading_post: true,
            page: page_num
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: state.fanpage_active,
                page: page_num,
                lang: i18n.language
            })
        };
        fetch(api.get_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data_api: result.datas,
                        loading_page: false,
                        loading_post: false,
                        last_page: result.last_page
                    })
                } else {
                    setState({
                        ...state,
                        loading_page: false,
                        loading_post: false
                    })
                }
            }
        )
    }

    const clearProcess = (code, data) => {
        var new_process = state.process.filter(function(el) { return el.code != code});
        const body = {
            fanpage_id: state.fanpage_active,
            page: state.page,
            lang: i18n.language
        }
        fetch(api.get_post, requestOptions('POST', cookies.is_login, body))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data_api: result.datas,
                        last_page: result.last_page,
                        process: new_process
                    })
                }
            }
        )
        Swal.fire({
            title: t("post.postProcessComplete"),
            text: t("post.postPublishSummary", {
                success: data.total_post_publish_success,
                error: data.total_post_publish_error
            }),
            // 'Thành công: ' + data.total_post_publish_success + ' page, không thành công: ' + data.total_post_publish_error,
            icon: 'success'
        })
    }

    const onCreateContent = (data) => {
        setState({
            ...state,
            process: [...state.process, {code: data.process_code, total: data.total_post}]
        })
        const body = {
            process_code: data.process_code,
            lang: i18n.language
        }
        fetch(api.publish_post_to_facebook, requestOptions('POST', cookies.is_login, body));
        // const posts = [...state.data_api.posts];
        // const new_posts = data.concat(posts);
        // setState({
        //     ...state,
        //     data_api: {
        //         ...state.data_api,
        //         posts: new_posts
        //     }
        // })
    }

    const updateGroupPage = (data) => {
        const old_group_pages = [...state.group_pages];
        const new_group_pages = old_group_pages.concat(data);
        setState({
            ...state,
            group_pages: new_group_pages
        })
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            {
                state.loading_page
                ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Grid item xs={12} md={12}>
                    <p>
                        <Button
                            className="text-transform"
                            startIcon={<ArrowBackRoundedIcon/>}
                            variant="contained"
                            color="inherit"
                            onClick={() => navigate(-1)}
                        >{t("common.back")}</Button>
                    </p>
                    <br></br>
                    <FormSearch
                        data_api={state.data_api}
                        search={search}
                        fanpage_id={id}
                        cookie={cookies.is_login}
                        updateGroupPage={updateGroupPage}
                    />
                    {/* <form onSubmit={handleSubmitSearch(search)}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} md={3}>
                                <Autocomplete
                                    id="tags-outlined"
                                    options={state.data_api?.fanpages}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={state.data_api.fanpage}
                                    // onChange={handleChangeFanpage}
                                    filterSelectedOptions
                                    size="small"
                                    renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        {...registerSearch('fanpage_id')}
                                        label="Chọn Fanpage"
                                    />
                                    )}
                                />
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    fullWidth
                                    label="Chọn Fanpage"
                                    defaultValue={id}
                                    {...registerSearch('fanpage_id')}
                                >
                                    {
                                        state.data_api?.fanpages.map((value, index) => {
                                            return (
                                                <MenuItem value={value.fanpage_id}>{value.name}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                                <TextField select fullWidth size="small" id="outlined-basic" label="Chọn Fanpage" variant="outlined" />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Button type="submit" variant="contained" color="primary">Tìm kiếm</Button>
                            </Grid>
                        </Grid>
                    </form> */}
                    <br></br>
                    <h2 className="m-t-0 display-flex space-between">
                        <span>{t("post.postList")}</span>
                        <DialogCreateContent
                            state={state}
                            cookie={cookies.is_login}
                            onCreateContent={onCreateContent}
                            group_pages={state.group_pages}
                        />
                    </h2>
                    {
                        state.process.length > 0 &&
                        state.process.map((value, key) => {
                            return (
                                <ProcessPublishPost
                                    key={key}
                                    stt={key}
                                    cookie={cookies.is_login}
                                    data_process={value}
                                    clearProcess={clearProcess}
                                />
                            )
                            
                        })
                    }
                    
                    {
                        state.loading_post
                        ?
                        <div className="t-center">
                            <CircularProgress/>
                        </div>
                        :
                        state.data_api?.posts != undefined && Array.isArray(state.data_api?.posts) ?
                            state.data_api.posts.length > 0 ?
                                state.data_api.posts.map((value, key) => {
                                    return (
                                        <div className="post-item">
                                            <p className="m-t-0">
                                                <DeletePost
                                                    value={value}
                                                    page={state.page}
                                                    key={key}
                                                    index={key}
                                                    cookie={cookies.is_login}
                                                    deletePost={deletePost}
                                                />
                                                <Button className="m-r-5" onClick={() => showModal(modal, value.content, value.post_id, key)} variant="contained" startIcon={<EditRoundedIcon/>} color="warning">{t("common.edit")}</Button>

                                                <a href={'https://www.facebook.com/' + value.post_id} className="a-none" target="_blank">
                                                    <Button className="m-r-5" variant="contained" color="info" startIcon={<CallMadeRoundedIcon/>}>{t("common.access")}</Button>
                                                </a>
                                                {
                                                    value.is_publish_error ?
                                                    <Button onClick={() => rePublish(value)} variant="contained" color="secondary" startIcon={<ReplayIcon/>}>
                                                        {t("common.repost")}
                                                    </Button> : ''
                                                }
                                                {/* <button onClick={() => showModal(modal, value.content, value.post_id)}>Edit</button>
                                                <button onClick={() => showModalComment(comment, value.post_id)}>Comment</button>
                                                <button onClick={() => showModalListComment(modal_list_comment, value.post_id)}>List comment</button> */}
                                            </p>
                                            {
                                                value.date_run != '' && value.date_run != undefined &&
                                                <p>
                                                    <i>
                                                    {/* Bài viết sẽ được đăng vào lúc <b>{value.date_run}</b> */}
                                                        <Trans
                                                            i18nKey="post.postScheduled"
                                                            values={{ date_run: value.date_run }}
                                                            components={{ b: <b /> }}
                                                        />
                                                    </i>
                                                </p>
                                            }
                                            {
                                                value.is_publish_error && value.log_publish != '' ?
                                                <p>
                                                    <Chip color="error" label={t('common.message.error') +": " + value.log_publish}/>
                                                </p>
                                                : ''
                                            }
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <Chip
                                                    size="medium"
                                                    avatar={<Avatar alt={value.fanpage_name} src={value.fanpage_avatar} />}
                                                    label={value.fanpage_name}
                                                    variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {
                                                        value.content != null && parse(value.content)
                                                    }
                                                </Grid>
                                            </Grid>
                                            <br></br>
                                            {
                                                value.attachments.length > 0 &&
                                                <Grid container spacing={2}>
                                                    {
                                                        value.attachments.map((attachment, index) => {
                                                            return (
                                                                <Grid item xs={3} md={3}>
                                                                    <img src={attachment} width="100%"></img>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            }
                                        </div>
                                    )
                                })
                            :
                            <center>
                               {t("common.message.emptyData")}
                            </center>
                        :
                        <span>
                            <i>{t("common.message.emptyData")}</i>
                        </span>
                    }
                    <br></br>
                    {
                        state.last_page > 1 &&
                        <Grid item xs={12} md={12} className="display-flex justify-content-center">
                            <Stack spacing={2}>
                                <Pagination
                                    onChange={handleChangePage}
                                    color="primary"
                                    count={state.last_page}
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </Grid>
                    }
                    <br></br>
                    {
                        state.dialog &&
                        <Dialog open={state.dialog} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog: false, post_id: '', index_edit: ''})}}>
                            <DialogTitle>{t("post.postInfo")}</DialogTitle>
                            <DialogContent>
                                <form onSubmit={handleSubmit(onEditPostSubmit)}>
                                    <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                        <Grid item xs={12}>
                                            <input {...register("post_id")} type="hidden" defaultValue={state.post_id}></input>
                                            <textarea {...register("content")} placeholder={t("post.updateContentHere")} className="form-control" rows={20}></textarea>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton className="text-transform" type="submit" size="large" fullWidth loading={state.loading_submit} variant="contained">
                                               {t('common.confirm')}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </DialogContent>
                        </Dialog>
                    }
                    {
                        comment &&
                        <div className="modal">
                            <div className="modal-dialog">
                                <p className="m-t-0 m-b-0 t-right">
                                    <span onClick={() => closeModalComment(comment)} className="close">X</span>
                                </p>
                                <div className="body">
                                    <h3 className="m-t-0">{t("post.enterEditContent")}</h3>
                                    <form onSubmit={handleSubmit(onCommentSubmit)}>
                                        <input {...register("post_id")} type="hidden" defaultValue={postId}></input>
                                        <textarea {...register("message")} placeholder={t("post.commentContent")} className="form-control" rows={10}></textarea>
                                        <button type="submit">{t("post.submitComment")}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        modal_list_comment && 
                        <div className="modal">
                            <div className="modal-dialog">
                                <p className="m-t-0 m-b-0 t-right">
                                    <span onClick={() => closeModalListComment(modal_list_comment)} className="close">X</span>
                                </p>
                                <div className="body">
                                    <h3 className="m-t-0">{t("post.commentList")}</h3>
                                    {
                                        listComment(list_comment, cookies.is_login, postId, t, i18n)
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </Grid>
            }
        </Container>
    )
}

export default Index;
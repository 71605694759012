import logo from "./logo.svg";
import "./Css/dashboard.scss";
import { BrowserRouter, Routes as Switch, Route, useSearchParams } from "react-router-dom";
import Login from "./Components/Pages/Logins/Index";
import ForgetPassword from "./Components/Pages/ForgetPasswords/Index";
import ResetPassword from "./Components/Pages/ResetPasswords/Index";
import LoginWithPassword from "./Components/Pages/LoginWithPasswords/Index";
import UpdateAfterLogin from "./Components/Pages/UpdateAfterLogin/Index";
import { CookiesProvider } from "react-cookie";
import DashboardRoute from "./Router/Index";
import BillOrder from "./Components/Dashboards/Pages/Orders/Components/BillOrder";
import ViettelPost from "./Components/Dashboards/Pages/PrintBills/ViettelPost";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "./Apis";

function App() {
  const { t, i18n } = useTranslation();
	const searchParams = new URLSearchParams(window.location.search);


  useEffect(() => {
		const countryParams = searchParams.get('country');
		const lang =  JSON.parse(localStorage.getItem("lang"));
		
		if(countryParams && (countryParams == "vn"  || countryParams == "vi")){
				i18n.changeLanguage("vi");
		} else {
			if(lang) {
				i18n.changeLanguage(lang === "VN" ? "vi" : "en");
			} else {
			 getLocation().then(data => {
				i18n.changeLanguage(data.country === "VN" ? "vi" : "en");
				localStorage.setItem("lang", JSON.stringify(data.country))
			 }) ;
			 
			}
		}
  }, []);

	useEffect(() => {
		document.title = t("page5sDescription");
	},[i18n.language])
	

	const getLocation = async () => {
		try {
			const response = await fetch(api.get_ip_info);
			const data = await response.json();
			return data
		} catch (error) {
			console.log(error);
		}
	}

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/print-bill-order/:id" element={<BillOrder />} />
          <Route path="/print-bill/:id" element={<ViettelPost />} />
          <Route path="/" element={<Login />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          {/* <Route path="/login-with-password" element={<LoginWithPassword />} /> */}
          <Route path="/dashboard/*" element={<DashboardRoute />} />
          <Route path="/:uid/update-login" element={<UpdateAfterLogin />} />
        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
